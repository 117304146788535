@keyframes flipIn {
    from {
        max-height: 0;
        -webkit-transform: rotatex(90deg) translatey(-50%);
        transform: rotatex(90deg) translatey(-50%);
        margin-bottom: -65px;
        opacity: 0;
    }
    to {
        max-height: none;
        -webkit-transform: none;
        transform: none;
        margin-bottom: 20px;
        opacity: 1;
    }
}
@-webkit-keyframes flipIn {
    from {
        max-height: 0;
        -webkit-transform: rotatex(90deg) translatey(-50%);
        transform: rotatex(90deg) translatey(-50%);
        margin-bottom: -65px;
        opacity: 0;
    }
    to {
        max-height: none;
        -webkit-transform: none;
        transform: none;
        margin-bottom: 20px;
        opacity: 1;
    }
}
@keyframes flipOut {
    from {
        max-height: none;
        -webkit-transform: none;
        transform: none;
        margin-bottom: 20px;
        opacity: 1;
    }
    to {
        max-height: 0;
        -webkit-transform: rotatex(90deg) translatey(-50%);
        transform: rotatex(90deg) translatey(-50%);
        margin-bottom: -65px;
        opacity: 0;
    }
}
@-webkit-keyframes flipOut {
    from {
        max-height: none;
        -webkit-transform: none;
        transform: none;
        margin-bottom: 20px;
        opacity: 1;
    }
    to {
        max-height: 0;
        -webkit-transform: rotatex(90deg) translatey(-50%);
        transform: rotatex(90deg) translatey(-50%);
        margin-bottom: -65px;
        opacity: 0;
    }
}
.shopify-buy__cart-items {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}
.shopify-buy__cart-item {
    -webkit-animation: 200ms flipIn forwards;
    animation: 200ms flipIn forwards;
}
.shopify-buy__cart-item.is-hidden {
    -webkit-animation-name: flipOut;
    animation-name: flipOut;
}

.shopify-buy-frame--toggle:not(.is-sticky) {
    overflow: visible !important;;
}
